import React from 'react';
import ReactDOM from 'react-dom/client';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ReportPage from './pages/Report';
import HomePage from './pages/Home';

import './wasm_exec.js';

import './index.css';
import AbstractsPage from './pages/Abstracts';

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage/>,
  },
  {
    path: "/report",
    element: <ReportPage/>,
  },
  {
    path: "/abstracts",
    element: <AbstractsPage/>,
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);


const go = new Go();
WebAssembly.instantiateStreaming(fetch("main.wasm").then(async (resp) => {

  return new Response(resp.body, {headers: {
    'Content-Type': 'application/wasm'
  }});
}), go.importObject).then((result) => {
    go.run(result.instance);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
