// TODO: Consider using TypedJSON to perform deserialization and serialization

export type ChartPValueType = Record<string, Record<string, number>>;

export type ChartDataType = Record<string, number[]>;

export type GroupMapType = Record<string, string[]>;

export class Abstract {
    id: number
    year: number
    title: string
    authors: Array<string>
    category: string
    keywords: Array<string>
    

    constructor(id: number, data: any) {
        this.id = id
        this.year = data?.year ?? 2024;
        this.title = data?.title ?? "";
        this.authors = data?.authors ?? [];
        this.category = data?.category ?? "";
        this.keywords = data?.keywords ?? [];

        this.title = this.title.replace("&nbsp;", "")
    }
}

export class Abstracts {

    constructor(data: any) {
    }

    // apiGroup.GET("/report/create", handler.CreateReport)
    static async getAbstracts(): Promise<Array<Abstract>> {
        const data = await fetch('/sitc-abstracts.json')

        let rawAbstracts = await data.json();
        let results: Array<Abstract> = [];

        let count = 0;
        for (let row in rawAbstracts) {
            const rowData = rawAbstracts[row];
            results.push(new Abstract(count, rowData))
            count += 1;
        }
        return results
    }

}
