import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Abstract, Abstracts } from '../api/Abstracts';

export interface IAbstractsPageProps {}

export type AbstractsParams = {
};


export class Filter {

    text: string
    isComplex: boolean
    parts: string[]
    
    constructor(data: string) {
        this.text = data;
        this.isComplex = this.text.search(",") > 0
        this.parts = this.text.split(',').map<string>((val) => {return val.trim()}).filter((val) => val.length > 0)
    }

    check(abstract: Abstract): boolean {
        for (let text of this.parts) {
            let filterText = text.toLowerCase();
            if (abstract.title.toLowerCase().includes(filterText) ||
            abstract.keywords.join(" ").toLowerCase().includes(filterText)) {
                return true;
            }
        }
        return false
    }
}

const AbstractsPage: React.FunctionComponent<IAbstractsPageProps> = (props) => {
    
    const inputRef = useRef<HTMLInputElement>(null);

    const [searchParams] = useSearchParams();
    
    const [filters, setFilters] = useState<Array<Filter>>([]);
    const [abstracts, setAbstracts] = useState<Array<Abstract>>([]);
    const [display, setDisplay] = useState<Array<Abstract>>([]);


    useEffect(() => {
        addFilter(searchParams.getAll("filter"));

    }, [searchParams])

    useEffect(() => {
        async function fetchAndSet() {
            let data = await Abstracts.getAbstracts();
    
            setAbstracts(data)
        }
        fetchAndSet();
    }, [setAbstracts]);

    useEffect(() => {
        let match: Array<Abstract> = [];

        if (filters.length > 0) {
            for (let abstract of abstracts) {
                let allMatched = true;
    
                for (let filter of filters) {    
                    allMatched = allMatched && filter.check(abstract);
                }
    
                if (allMatched) {
                    match.push(abstract);
                }
            }
        }


        setDisplay(match)

        const url = new URL(window.location.href);
        url.searchParams.delete("filter");

        for (let filter of filters) {
            url.searchParams.append('filter', filter.text);
        }

        window.history.replaceState(null, '', url.toString());
    }, [filters, abstracts, setAbstracts, setDisplay]);

    const promptDownload = useCallback(() => {

        let exportCsvText = 'SITC Year,Title,Category,Authors,Keywords\n';

        let exportRows = display;
        if (filters.length === 0) {
            exportRows = abstracts
        }


        for (let abstract of exportRows) {
            exportCsvText += `"${abstract.year}",`;
            exportCsvText += `"${abstract.title}",`;
            exportCsvText += `"${abstract.category}",`;
            exportCsvText += `"${abstract.authors.join('; ')}",`;
            exportCsvText += `"${abstract.keywords.join('; ')}"\n`;
        }

        const blob = new Blob([exportCsvText], {type: 'text/csv'});
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = 'abstracts.csv';        
        document.body.appendChild(elem);
        elem.click();        
        document.body.removeChild(elem);

    }, [display, filters, abstracts]);

    const addFilter = useCallback((fields: string[]) => {

        let newFilters: Array<Filter> = [];

        for (let filterString of fields) {
            if (filterString.trim() == "") {
                continue
            }

            let allowed = true;

            for( let filter of filters) {
                if (filter.text.trim() === filterString.toLowerCase().trim()) {
                    allowed = false;
                    break
                }
            }
            

            if (allowed) {
                let newFilter = new Filter(filterString);
                newFilters.push(newFilter);
            }

        }

        if (newFilters.length > 0) {
            setFilters([...filters, ...newFilters]);
        }

        if (inputRef.current !== null) {
            inputRef.current.value = "";
        }
    }, [filters, setFilters, inputRef]);

    const removeFilter = useCallback((filter: Filter) => {
        var newFilters = [];

        for (let index in filters) {
            let f = filters[index];

            if (f == filter) {
                continue
            }
            newFilters.push(f);
        }

        setFilters(newFilters);
    }, [filters, setFilters]);

    const clearFilters = useCallback(() => {
        setFilters([]);
    }, [setFilters]);

    const checkSend = useCallback((e: any) => {
        if (e.key === 'Enter') {
            addFilter([e.target.value]);
        }
    }, [addFilter]);

    const filterView = filters.map((filter, index) => {
        let andCombo = <></>
        if (index < filters.length-1) {
            andCombo = <span className='select-none text-white items-center font-semibold text-sm'>AND</span>
        }

        let output = <></>;

        if (filter.isComplex) {
            let conditions = filter.parts.map<ReactNode>((text, index) => {
                let condKeyVal = `${filter.text}-${index}`;
                let orCombo = <></>
                if (index < filter.parts.length-1) {
                    orCombo = <span key={`${condKeyVal}-or`} className='select-none text-white items-center font-semibold text-sm'>OR</span>
                }
                return (
                    <div key={`${condKeyVal}`} className='flex flex-row items-center'>
                        <div className='select-none badge badge-md m-1 font-semibold bg-green-400 border border-green-700 text-green-950 hover:bg-red-400 hover:border-red-700 hover:text-white hover:cursor-pointer'
                            onClick={() => removeFilter(filter)}
                        >
                            <span>{text}</span>
                        </div>
                        {orCombo}
                    </div>
                );
            });

            output = (
                <div key={filter.text} className='flex flex-row items-center'>
                    <div className='flex flex-row items-center'>
                        <span className='select-none text-white font-bold text-3xl mb-2 pl-1'>(</span>
                        {conditions}
                        <span className='select-none text-white font-bold text-3xl mb-2 pr-1'>)</span>
                    </div>
                    {andCombo}
                </div>
            );
        } else {
            output = (
                <div key={filter.text} className='flex flex-row items-center'>
                    <div className='select-none badge badge-lg m-1 font-semibold bg-green-400 hover:bg-red-400 hover:border-red-700 hover:text-white border hover:cursor-pointer border-green-700 text-green-950'
                        onClick={() => removeFilter(filter)}
                    >
                        <span>{filter.text}</span>
                    </div>
                    {andCombo}
                </div>
            );
        }

        return output
    })


    let rows = <></>;
    let dataTable = <div className='flex flex-col mt-10 text-white text-lg max-w-4xl items-center'>
        <span className='mt-8 text-white text-2xl font-semibold '>
            Add a filter to begin searching abstracts.
        </span>

        <ol className="flex flex-col">
            <li className='text-white text-xl'>
                1) Filters will look for a match in an abstract's title or keywords (case insensitive).
            </li>
            <li className='text-white text-xl'>
                2) If a filter contains phrases separated by commas (ie, "trial, phase"), only one of the phases needs to match for the filter to pass.
            </li>
            <li className='text-white text-xl'>
                3) Applying multiple filters will only show abstracts that match all of the filters.
            </li>
        </ol>

        <div className='flex flex-col items-center mt-8'>
            <span className='text-2xl font-bold'>Examples</span>

            <div>
                <div className='flex flex-row items-center'>
                    <button className='btn text-black bg-green-500 m-1 border-2 hover:border-2 border-black hover:bg-green-300 btn-md text-lg'
                        onClick={() => {
                            addFilter(['vaccine', 'DC-1,DC1,dendritic']);
                        }}
                    >
                        Try
                    </button>
                    <div className='ml-4 pb-2'>
                        <span className='select-none border rounded-lg p-1 px-4 bg-blue-600 border-blue-800 font-semibold'>vaccine</span>
                        <span className='select-none text-2xl font-bold px-2'>+</span>
                        <span className='select-none border rounded-lg p-1 px-4 bg-blue-600 border-blue-800 font-semibold'>DC-1, DC1, dendritic</span>
                    </div>
                </div>
                <div className='flex flex-row items-center'>
                    <button className='btn text-black bg-green-500 m-1 border-2 hover:border-2 border-black hover:bg-green-300 btn-md text-lg'
                        onClick={() => {
                            addFilter(['vaccine', 'clinical, trial, phase, report', 'DNA, mRNA, protein, peptide']);
                        }}
                    >
                        Try
                    </button>
                    <div className='ml-4 pb-2'>
                        <span className='select-none border rounded-lg p-1 px-4 bg-blue-600 border-blue-800 font-semibold'>vaccine</span>
                        <span className='select-none text-2xl font-bold px-2'>+</span>
                        <span className='select-none border rounded-lg p-1 px-4 bg-blue-600 border-blue-800 font-semibold'>clinical, trial, phase, report</span>
                        <span className='select-none text-2xl font-bold px-2'>+</span>
                        <span className='select-none border rounded-lg p-1 px-4 bg-blue-600 border-blue-800 font-semibold'>DNA, mRNA, protein, peptide</span>
                    </div>
                </div>
                <div className='flex flex-row items-center '>
                    <button className='btn text-black bg-green-500 m-1 border-2 hover:border-2 border-black hover:bg-green-300 btn-md text-lg'
                        onClick={() => {
                            addFilter(['neoantigen', 'clinical,trial,phase,report']);
                        }}
                    >
                        Try
                    </button>
                    <div className='ml-4 pb-2'>
                        <span className='select-none border rounded-lg p-1 px-4 bg-blue-600 border-blue-800 font-semibold'>neoantigen</span>
                        <span className='select-none text-2xl font-bold px-2'>+</span>
                        <span className='select-none border rounded-lg p-1 px-4 bg-blue-600 border-blue-800 font-semibold'>clinical, trial, phase, report</span>
                    </div>
                </div>
                <div className='flex flex-row items-center '>
                    <button className='btn text-black bg-green-500 m-1 border-2 hover:border-2 border-black hover:bg-green-300 btn-md text-lg'
                        onClick={() => {
                            addFilter(['LNP,lipid nanoparticle']);
                        }}
                    >
                        Try
                    </button>
                    <div className='ml-4 pb-2'>
                        <span className='select-none border rounded-lg p-1 px-4 bg-blue-600 border-blue-800 font-semibold'>LNP, lipid nanoparticle</span>
                    </div>
                </div>
            </div>
        </div>
    </div>;
    if (filters.length > 0) {

        let rows = display.map((abstract) => {
            const keyVal = `${abstract.id}-${abstract.year}+${abstract.title}`;
    
            const keywords = abstract.keywords.map((keyword) => {
                return (
                    <div key={`${keyVal}-${keyword}`} className='text-xs badge badge-md m-1 text-gray-200 font-semibold bg-slate-500 border border-slate-800'>{keyword}</div>
                )
            })
    
            return (
                <tr className='even:bg-slate-600 odd:bg-slate-700 border border-slate-900 overflow-x-hidden w-full' key={keyVal}>
                    <td className="text-gray-200 px-4 font-semibold">{abstract.year}</td>
                    <td className="flex shrink text-gray-100 text-center py-2 justify-center">{abstract.title}</td>
                    <td className="max-w-1/2">{keywords}</td>
                    <td className="">
                        <a href={`https://google.com/search?q="${abstract.title}"`} target='blank' className='btn m-1 border bg-green-500 hover:bg-green-300 text-black border-black hover:border-black btn-sm'>Find</a>
                    </td>
                </tr>
            );
        });
        
        dataTable = <table className='table-auto w-full border border-slate-900 mb-10'>
            <thead className=''>
                <tr className='bg-slate-800 items-center justify-center'>
                    <th className='text-white font-bold'>Year</th>
                    <th className='text-white font-bold'>Title</th>
                    <th className='text-white font-bold'>Keywords</th>
                    <th className='text-white font-bold w-20'></th>
                </tr>
            </thead>
            <tbody className=''>
                {rows}
            </tbody>
        </table>;
    }

    return (
        <div className="flex flex-col overflow-hidden h-screen bg-base-content">
            <header>
                <div className="flex navbar bg-neutral-focus">
                    <div className="flex-1">
                        <Link to="/" className="text-base-100 font-bold normal-case text-xl px-5">
                            Bio Report
                        </Link>
                    </div>
                    <div className="flex-none mr-4">
                        <button className='btn text-black bg-green-500 m-1 border-2 hover:border-2 border-black hover:bg-green-300 btn-md' onClick={() => {promptDownload()}}>
                            Export CSV
                            <span className='border-2 rounded-lg p-1 ml-2 bg-green-200 border-green-800'>{display.length > 0 ? display.length : abstracts.length}</span>
                        </button>
                    </div>
                </div>
            </header>

            <main className="w-full overflow-hidden bg-base-content justify-items-center">
                
                <div className='flex flex-col m-4 items-center'>
                    <div className='flex flex-row items-center'>
                        <div className='mr-4'>
                            <input ref={inputRef} className='text-lg p-2 border-2 border-black rounded-md w-80 h-12' placeholder='Filter keyword' 
                                onKeyDown={e => checkSend(e)}
                            >
                                
                            </input>
                        </div>
                        <div>
                            <button className='btn text-lg text-black bg-green-500 m-1 border-2 hover:border-2 border-black hover:bg-green-300 btn-md' onClick={() => {addFilter([inputRef.current?.value ?? ""])}}>Add</button>
                            <button className='btn text-lg text-black bg-red-500 m-1 border-2 hover:border-2 border-black hover:bg-red-300 btn-md' onClick={() => clearFilters()}>Clear All</button>
                        </div>
                    </div>
                    <div className='flex flex-row flex-wrap items-center justify-center mt-2'>
                        {filterView}
                    </div>
                </div>
            </main>

            <div className='flex-1 w-full justify-items-center bg-base-content overflow-y-scroll scrollbar border-t-2 border-t-gray-500 px-4'>
                {dataTable}
            </div>

            <footer></footer>
        </div>
    );
};

export default AbstractsPage;
