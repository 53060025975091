import React from 'react';
import { Link } from 'react-router-dom';

export interface IHomePageProps {}

const HomePage: React.FunctionComponent<IHomePageProps> = (props) => {
    return (
        <div className="bg-base-content flex flex-col h-screen justify-between">
            <header>
                <div className="navbar bg-neutral-focus">
                    <div className="flex-1">
                        <Link to="/" className="text-base-100 font-bold normal-case text-xl px-5">
                            Bio Report
                        </Link>
                    </div>
                </div>
            </header>

            <main className="flex flex-row flex-wrap justify-center md:space-x-8 space-y-8 md:space-y-0">
                <div className="card card-compact w-96 bg-base-100 shadow-xl">
                    <div className="card-body">
                        <h2 className="card-title">WSP Report Generator</h2>
                        <p>Combine multiple WSP files into one aggregated dataset with different group filters applied.</p>
                        <div className="card-actions justify-end">
                            <Link to={'/report'} className="btn btn-primary">
                                Create Report
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="card card-compact w-96 bg-base-100 shadow-xl">
                    <div className="card-body">
                        <h2 className="card-title">SITC Abstract Search</h2>
                        <p>Search through SITC Abstracts from 2018-2024.</p>
                        <div className="card-actions justify-end">
                            <Link to={'/abstracts'} className="btn btn-primary">
                                Search Abstracts
                            </Link>
                        </div>
                    </div>
                </div>
            </main>

            <footer></footer>
        </div>
    );
};

export default HomePage;
